<template>
    <div class="gk-card as-listing-item d-flex justify-content-start align-items-center p-4" v-if="broadcaster">
        <div class="mr-4">
            <router-link
                class="title mb-1"
                tag="a"
                :to="{ query: getGlobalCreatorRouteQuery('broadcaster', broadcaster.id) }">
                {{ broadcaster.name }}
            </router-link>
            <p class="mb-0 small text-muted" v-if="broadcaster.description">{{ broadcaster.description }}</p>
            <p class="mb-0 small"><i class="far fa-envelope"></i> {{ broadcaster.emailCampaignFromName }} - {{ broadcaster.emailCampaignFromEmail }}</p>
            <p class="mb-0 small"><i class="fas fa-comment-dots"></i> {{ broadcaster.smsCampaignFromName }}</p>
            <p class="mt-2 mb-0 text-muted text-microcopy--65">{{ $t('audit_created', { dateTime: dateLiteral(broadcaster.meta.createdDate), actor: broadcaster.meta.createdBy }) }}</p>
            <p class="mb-0 text-muted text-microcopy--65">{{ $t('audit_updated', { dateTime: dateLiteral(broadcaster.meta.lastModifiedDate), actor: broadcaster.meta.lastModifiedBy }) }}</p>
        </div>
    </div>
</template>

<script>
/**
 * Broadcaster listing item for Broadcasters view.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2019
 * @lastModifiedAt 20 July 2020
 */
export default {
    name: 'BroadcasterListingItem',
    props: {
        broadcaster: {
            type: Object,
            default: null,
            required: true
        }
    }
};
</script>
