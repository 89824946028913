<template>
    <div class="BroadcastersView ViewWrapper">
        <clv-head-meta :title="$tc('entity Broadcaster', 2)"></clv-head-meta>
        <view-header>
            <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                <h1>{{ $tc('entity Broadcaster', 2) }}</h1>
                <!--suppress HtmlUnknownAttribute -->
                <div class="mt-2 mt-md-0" v-tooltip="applicationQuotasUiEntityQluVTooltipObject('broadcaster')">
                    <router-link tag="button"
                                 class="btn btn-primary"
                                 :to="{ query: getGlobalCreatorRouteQuery('broadcaster') }"
                                 :disabled="applicationQuotasUiEntityQluDisabled('broadcaster')">{{ $t('Create Broadcaster') }}</router-link>
                </div>
            </div>
        </view-header>
        <view-container>
            <!-- LISTING LOADER -->
            <div class="ElementLoading-Placeholder" v-if="fetching">
                <element-loading :active="true" background-color="transparent"></element-loading>
            </div>

            <!-- EMPTY STATE 2020-07-19 -->
            <simple-state-empty v-if="displayEmptyState">
                <div class="mt-4" slot="action">
                    <router-link tag="button" class="btn btn-accent1" :to="{ query: getGlobalCreatorRouteQuery('broadcaster') }">{{ $t('Create Broadcaster') }}</router-link>
                </div>
            </simple-state-empty>

            <!-- ERROR STATE 2020-07-19 -->
            <simple-state-error v-if="displayError" :error="error"></simple-state-error>

            <!-- LISTING -->
            <div class="row mt-4" v-if="displayListing">
                <div class="col-12">
                    <broadcaster-listing-item v-for="(dataItem, dataItemIndex) in data"
                                              :broadcaster="dataItem"
                                              :index="dataItemIndex"
                                              :key="dataItem.id"></broadcaster-listing-item>
                </div>
            </div>

            <!-- PAGINATION -->
            <div class="row mt-4" v-show="displayPagination">
                <div class="col-12">
                    <custom-cv-pagination :backward-text="$t('common_pagination_next')"
                                          :forward-text="$t('common_pagination_previous')"
                                          :page-sizes-label="$t('common_pagination_page_size_label', { object: $tc('entity Broadcaster', 2) })"
                                          :number-of-items="dataCount"
                                          :page="listingPagePlusOne"
                                          :page-sizes="listingSizeOptions"
                                          @change="carbonPaginationChange"></custom-cv-pagination>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GenericListingMixin from '@/store/generics/GenericListingMixin';
import BroadcasterListingItem from './BroadcasterListingItem';

/**
 * Broadcasters view.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2019
 * @lastModifiedAt 20 July 2020
 */
export default {
    name: 'BroadcastersView',
    components: {
        BroadcasterListingItem
    },
    mixins: [
        GenericListingMixin('Broadcasters', 'broadcasters')
    ],
    computed: {
        ...mapGetters('application', {
            applicationQuotasUiEntityQluDisabled: 'quotasUiEntityQluDisabled',
            applicationQuotasUiEntityQluVTooltipObject: 'quotasUiEntityQluVTooltipObject'
        })
    },
    beforeMount () {
        this.getData();
    }
};
</script>
